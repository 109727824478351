.navlogo {
  width: 4.5rem;
  height: 4.5rem;
  margin: 0.5rem;
}
.svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}
.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.navbar {
  background: white;
}

.navbar-brand {
  font-weight: 600;
  font-size: 1.3rem;
  color: #7a0d0d !important;
}
.navbar-brand:hover,
.nav-link:hover {
  color: #7a0d0d;
}
.navbar-toggler {
  margin-right: 1rem;
}

.nav-link {
  margin-left: 1rem;
}

/* Custom CSS for Mobile View */
@media (max-width: 768px) {
  .navbar-brand img {
    max-width: 80%; /* Adjust the logo size as needed */
  }

  /* .navbar {
    padding: 0.5rem;
  } */

  /* Custom CSS for Mobile View */

  .navbar-brand,
  .nav-link {
    left: 1.2rem;
    position: relative;

    font-size: 1rem; /* Adjust the font size as needed */
  }
}

/* Custom CSS for Mobile View */
@media (max-width: 992px) {
  .navbar-brand img {
    max-width: 80%; /* Adjust the logo size as needed */
  }
}

/* Custom CSS for Mobile View */
@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column; /* Stack items vertically */
  }

  .navbar-collapse {
    justify-content: center; /* Center items vertically */
  }
}

/* Custom responsive form styling */
.responsive-search-bar {
  width: 90%; /* Default to full width */
  margin: 0 auto; /* Center-align the form */
}

@media (min-width: 992px) {
  /* On large screens, apply 25% width and custom margin */
  .responsive-search-bar {
    width: 25%;
    margin-right: 1.25rem; /* Adjusts right margin */
    margin-top: 1.5rem; /* Adjusts top margin */
  }
}

/* .search-button {
  background-color: transparent;
  color: #7a0d0d;
  font-weight: 600;
  border: 1px solid #7a0d0d;
  border-radius: 20px;
}

.search-button:hover,
.search-button:active,
.search-button:focus,
.search-button:disabled {
  background-color: #7a0d0d; 
  color: #f6ebd4; 
} */

.search-box {
  /* border: 1px solid #7a0d0d; */
  border-radius: 20px;
}

.search-button {
  /* background-color: transparent; */
  color: white;
  background: linear-gradient(135deg, #f39c12, #f1c40f);
  font-weight: 600;
  border: 1px solid #f39c12;
  border-radius: 20px;
}

.search-button:hover,
.search-button:active,
.search-button:focus,
.search-button:disabled {
  /* background-color: #7a0d0d;  */
  background-color: linear-gradient(135deg, #f39c12, #f1c40f);
  color: white; 
}


