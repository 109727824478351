.fit-cover {
  object-fit: cover;
}

a:link {
  text-decoration: none;
  color: #7a0d0d;
}
a:visited {
  text-decoration: none;
  color: #7a0d0d;
}
a:hover {
  text-decoration: none;
  color: #7a0d0d;
}
a:active {
  text-decoration: none;
  color: #7a0d0d;
}

.content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.customimage {
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  display: block;
}

.center {
  text-align: center;
}

.card {
  background: #f6ebd4;
  border: 0px;
}

#root {
  background: #f6ebd4;
}

.card-title {
  font-weight: 700;
  font-size: 1.75rem;
  color: #7a0d0d;
}

.search-title {
  margin-bottom: 1rem;
  margin-top: 0;
  font-weight: 600;
  font-size: 2rem;
  /* bold */

  color: #7a0d0d;
}

.card-text {
  margin-bottom: 1rem;
  margin-top: 0;
  font-weight: 400;
  font-size: 1rem;
  color: #7a0d0d;
}

.content-title {
  font-weight: 500;
  font-size: 1.4rem;
  color: #7a0d0d;
}
.content-link {
  font-weight: 500;
  font-size: 1.4rem;
  color: #7a0d0d;
}

.customimage-container {
  width: 100%;
  height: 100%;
}

.max-height {
  min-height: 90vh;
}

.content-image {
  width: 100%;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.video-container iframe {
  width: 80%;
  height: 45vw;
}

.sub-color {
  color: #7a0d0d;
}

.light-color {
  color: #503f3f;
}
.share-button {
  border-radius: 1rem;
  padding: 0.25rem;
  border: 0.1rem solid #503f3f;
  color: #503f3f;
}
.center-video {
  display: flex;
  justify-content: center;
  width: 100%;
}

.video-wrapper {
  width: 50%;
}

.rhap_container {
  background: #f6ebd4 !important;
  box-shadow: none !important;
  border: none !important;
  width: 70% !important;
}

.rhap_main-controls-button {
  width: 80px !important;
  height: 80px !important;
}
.rhap_play-pause-button svg
/* .rhap_main-controls-button svg */ {
  color: #7a0d0d;
  width: 80%;
  height: 80%;
}
.rhap_progress-filled {
  background-color: #503f3f !important;
}

.rhap_progress-indicator {
  background-color: #503f3f !important;
}

.rhap_button-clear.rhap_volume-button {
  color: #503f3f !important;
  display: none;
}

.rhap_volume-bar,
.rhap_volume-indicator {
  background-color: #503f3f !important;
  display: none;
}
.rhap_additional-controls {
  color: #503f3f;
}

.rhap_repeat-button {
  color: #503f3f !important;
}

@media (max-width: 768px) {
  .rhap_container {
    width: 95% !important;
  }
}

/* .navlogo */
@media (max-width: 400px) {
  .navbar-brand img {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 0px;
    left: 0px;
  }
  .navbar-brand {
    margin: 0px;
  }
}

@media (min-width: 769px) {
  .rhap_container {
    width: 60% !important;
  }
}
@media (min-width: 1024px) {
  .rhap_container {
    width: 50% !important;
  }
}

.rhap_repeat-button {
  display: none;
}
.rhap_forward-button,
.rhap_rewind-button {
  width: 40%;
  height: 40%;
  color: #503f3f !important;
}
.rhap_progress-section {
  margin-bottom: 1%;
}

/* Feedback Form */
.rating-container {
  margin-bottom: 15px;
}

.stars {
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.star {
  font-size: 24px;
  color: #ccc;
  transition: color 0.2s;
}

.star.selected {
  color: #f39c12;
}

.star:hover,
.star:hover ~ .star {
  color: #f39c12;
}

/* Floating Button */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(135deg, #f39c12, #f1c40f);
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.floating-button:hover {
  transform: scale(1.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

/* Feedback Overlay */
.feedback-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Feedback Form Container */
.feedback-form-container {
  background: #ffffff;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  position: relative;
  transform: scale(0.8);
  animation: scaleIn 0.3s forwards;
}

@keyframes scaleIn {
  to {
    transform: scale(1);
  }
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #555;
  transition: color 0.2s;
}

.close-button:hover {
  color: #f39c12;
}

/* Labels and Inputs */
.feedback-form label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.feedback-form input,
.feedback-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 14px;
}

.feedback-form input:focus,
.feedback-form textarea:focus {
  outline: none;
  border-color: #f39c12;
  box-shadow: 0 0 4px rgba(243, 156, 18, 0.5);
}

/* Submit Button */
.feedback-form button[type="submit"] {
  width: 100%;
  padding: 12px;
  background: linear-gradient(135deg, #f39c12, #f1c40f);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.2s, transform 0.2s;
}

.feedback-form button[type="submit"]:hover {
  background: linear-gradient(135deg, #f1c40f, #f39c12);
  transform: translateY(-2px);
}

/* Star Rating */
.stars {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 15px;
}

.star {
  font-size: 24px;
  color: #ddd;
  cursor: pointer;
  transition: color 0.2s, transform 0.2s;
}

/* Highlight stars on hover */
.star:hover ~ .star {
  color: #ddd; /* Reset stars after the hovered star */
}

.star:hover,
.star:hover ~ .star:first-child,
.star:hover ~ .star:first-child + .star {
  color: #f39c12; /* Highlight hovered star and all before it */
  transform: scale(1.1); /* Slight zoom effect */
}

.floating-button svg {
  font-size: 32px;
}
/* Floating Button */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(135deg, #f39c12, #f1c40f);
  color: white;
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0; /* Ensures only the icon is visible */
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.floating-button:hover {
  transform: scale(1.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

/* Icon Styling */
.floating-button svg {
  font-size: 32px; /* Adjust size here */
}

.loading {
  color: #7a0d0d;
  margin: 2%;
  padding: 2%;
}
.link-color{
  color: blue;
}